
import modalConstants from '~/store/modal/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'

export default {
  name: 'OrganismShortlistDrawer',
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeTab: 'residential',
      selectedAmenities: []
    }
  },
  computed: {
    tabData() {
      const tabData = []
      tabData.push({
        label: this.$t('residential'),
        tabId: 'residential'
      })
      tabData.push({
        label: this.$t('projectInfo'),
        tabId: 'project-info'
      })
      return tabData
    },
    locale() {
      return this.$i18n?.locale || 'en'
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
    amenities() {
      return this.$store.state.base?.landlordDetails?.amenities || []
    },
    currency() {
      return this.$store.state?.base?.meta?.generalConfig?.baseCurrencySymbol || '€'
    },
    finalStructureSpaces() {
      const obj = {}
      this.shortlist?.forEach((sp) => {
        const building = sp.building
        if (!obj[sp.building_id]) {
          obj[sp.building_id] = {
            id: sp.building_id,
            title: building?.name || '',
            buildingDetails: building,
            child: {}
          }
        }

        if (!obj[sp.building_id].child[sp.floor_id]) {
          const floor = sp.floor
          obj[sp.building_id].child[sp.floor_id] = {
            id: sp.floor_id,
            title: floor?.name || '',
            subtitle: 'somesubtitle',
            floorDetails: floor.no,
            child: []
          }
        }

        const units =
          this.$store.state.base?.landlordDetails?.spaces?.find((s) => s.id === sp.id)?.units || []
        // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
        // const balconySurface =
        //   units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())?.surface ||
        //   0
        // const gardenSurface =
        //   units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())?.surface || 0
        obj[sp.building_id].child[sp.floor_id].child.push({
          id: sp.id,
          title: sp.alias ? `Ap. ${sp.alias}` : `Ap. ${sp.space_code}`,
          surface: sp.util_sqm,
          // total_sqm: Number((sp.constructed_sqm + balconySurface + gardenSurface).toFixed(2)),
          // useful_sqm: Number((sp.util_sqm + balconySurface + gardenSurface).toFixed(2)),
          total_sqm: Number(sp.constructed_sqm.toFixed(2)),
          useful_sqm: Number(sp.util_sqm.toFixed(2)),
          cardinalPoint: sp.orientation,
          content: {
            imgUrl:
              'https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/projects/intero/logo-black.png',
            description: this.mapAmenitiesTranslated(sp),
            price: `${sp.price.toLocaleString('de-DE')} ${this.currency} + ${this.$t('VAT')}`,
            buttons: []
          },
          spaceDetails: sp,
          units,
          bedrooms: this.getSpaceInfoNumberByType(units, 'Room', '', sp.number_of_rooms),
          balconies: this.getSpaceInfoNumberByType(
            units,
            'Balcony',
            'Balconies',
            sp.has_balcony ? 1 : 0
          ),
          bathrooms: this.getSpaceInfoNumberByType(units, 'Bathroom', '', sp.number_of_bathrooms)
        })
      })
      return obj
    },
    shortlist() {
      return this.$store.state?.requestOffer?.spaces || []
    }
  },
  methods: {
    mapAmenitiesTranslated(space) {
      let filteredAmenities =
        this.amenities?.filter((am1) => space.amenities.some((am2) => am1.id === am2.id)) || []
      filteredAmenities = filteredAmenities?.map((am) => am.translation.label[this.locale]) || []
      if (filteredAmenities.length === 0) return this.$t('No amenities found')
      return filteredAmenities.join(', ')
    },
    openRequestOfferModal() {
      if (!this.shortlist.length) return
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'shortlist'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer'
      })
      document.body.classList.add('disable-scroll')
    },
    exploreSpace(spaceId) {
      const routePageData = this.$route.meta.page
      this.$router.push({
        name: routePageData.isEmbedReady ? 'SpecificSpaceEmbed' : 'SpecificSpace',
        params: {
          projectId: this.activeProject,
          spaceId
        }
      })
    },
    removeFromShortlist(space) {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        space
      )
    },
    clearShortlist() {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.CLEAR_ALL)
      )
    },
    clearBuildingShortlist(buildingId) {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(
          requestOfferConstants.action.CLEAR_SPACES_FROM_BUILDING
        ),
        { buildingId }
      )
    },
    toggleDrawer() {
      this.$refs.drawer?.toggleFilter()
    },
    closeDrawer() {
      this.$refs.drawer?.close()
    },
    openDrawer() {
      this.$refs.drawer?.open()
    },
    handleExpandAvailability() {
      this.$emit('toggleAvailability')
    },
    isDefaultOpen(variable, idx) {
      if (Array.isArray(variable)) {
        return parseInt(Math.min(...variable), 10) === parseInt(idx, 10)
      }
      return Math.min(variable) === idx
    },
    getShortlistLengthByBuilding(buildingId) {
      const res =
        this.$store.state?.requestOffer?.spaces?.filter((s) => s.building_id === buildingId) || []
      return res.length
    },
    getSpaceInfoNumberByType(units, key, customKey = '', defaultCount = 0) {
      let noBedrooms = 0
      if (!units || units.length === 0)
        return {
          text: key,
          value: defaultCount
        }
      noBedrooms = units.reduce((acc, obj) => {
        if (obj.title === key) return acc + 1
        return acc
      }, 0)

      let text = ''
      switch (noBedrooms) {
        case 0:
          text = key
          break
        case 1:
          text = key
          break
        default:
          if (customKey !== '') {
            text = customKey
          } else {
            text = `${key}s`
          }
      }
      return {
        text,
        value: noBedrooms || defaultCount
      }
    },
    getMappedFloorText(floor) {
      let text = ''
      // TODO: refactor this Petrichi fix :P
      switch (parseInt(floor, 10)) {
        case -3:
          text = `${this.$t('FloorRo')} -3<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -2:
          text = `${this.$t('FloorRo')} -2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case -1:
          text = `${this.$t('FloorRo')} -1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 0:
          text = `${this.$t('Ground Floor')}`
          break
        case 1:
          text = `${this.$t('FloorRo')} 1<sup class="superior-text">${this.$t(
            'st'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 2:
          text = `${this.$t('FloorRo')} 2<sup class="superior-text">${this.$t(
            'nd'
          )}</sup>  ${this.$t('Floor')}`
          break
        case 3:
          text = `${this.$t('FloorRo')} 3<sup class="superior-text">${this.$t(
            'rd'
          )}</sup>  ${this.$t('Floor')}`
          break
        default:
          text = `${this.$t('FloorRo')} ${floor}<sup class="superior-text">${this.$t(
            'th'
          )}</sup>  ${this.$t('Floor')}`
      }
      return text
    }
  }
}
