
export default {
  name: 'OrganismListCircle',
  props: {
    listCircleData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  }
}
