
export default {
  name: 'OrganismPartner',
  props: {
    partnerData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    resourceUrl(img) {
      return `${this.$store.getters['base/cdnBase']}/${img}`
    }
  }
}
