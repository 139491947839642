import { render, staticRenderFns } from "./OrganismShortlistDrawer.vue?vue&type=template&id=6700662b&scoped=true&"
import script from "./OrganismShortlistDrawer.vue?vue&type=script&lang=js&"
export * from "./OrganismShortlistDrawer.vue?vue&type=script&lang=js&"
import style0 from "./OrganismShortlistDrawer.vue?vue&type=style&index=0&id=6700662b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6700662b",
  null
  
)

/* custom blocks */
import block0 from "@/lang/OrganismShortlistDrawer.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Forganisms%2Fcommon%2Favailability%2FOrganismShortlistDrawer.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomButton: require('/app/components/atoms/common/AtomButton.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,AtomsCommonAvailabilityAtomCollapsibleItem: require('/app/components/atoms/common/availability/AtomCollapsibleItem.vue').default,AtomsCommonAtomEmptyPlaceholder: require('/app/components/atoms/common/AtomEmptyPlaceholder.vue').default,OrganismsCommonOrganismDrawer: require('/app/components/organisms/common/OrganismDrawer.vue').default})
