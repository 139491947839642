
export default {
  name: 'OrganismProjectSlider',
  props: {
    sliderData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      activeThumb: 'item-0'
    }
  },
  methods: {
    handleThumbChange(id) {
      this.activeThumb = id
      this.$emit('thumb-change', id)
    }
  }
}
