
import projectConstants from '~/store/project/-constants'
import tutorialConstants from '~/store/tutorial/-constants'
import universeConstants from '~/store/universe/-constants'
import modalConstants from '~/store/modal/-constants'
import requestOfferConstants from '~/store/requestOffer/-constants'

export default {
  name: 'OrganismAvailabilityDrawer',
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false
    },
    enableEmbedVersion: {
      required: false,
      type: Boolean,
      default: false
    },
    disableExploreSpacePage: {
      required: false,
      type: Boolean,
      default: false
    },
    disableRequestOffer: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    const tabData = []
    tabData.push({
      label: this.$t('residential'),
      tabId: 'residential'
    })
    tabData.push({
      label: this.$t('projectInfo'),
      tabId: 'project-info'
    })
    return {
      activeTab: 'residential',
      selectedAmenities: [],
      drawerFiltersOpen: true,
      drawerShortlistOpen: false,
      clickedHighlight: ''
    }
  },
  computed: {
    shortlistState() {
      return this.$store.getters?.getShortlist || false
    },
    tabData() {
      const tabData = []
      tabData.push({
        label: this.$t('residential'),
        tabId: 'residential'
      })
      tabData.push({
        label: this.$t('projectInfo'),
        tabId: 'project-info'
      })
      return tabData
    },
    locale() {
      return this.$i18n?.locale || 'en'
    },
    activeProjectSlug() {
      return this.$store.state.project.activeProject
    },
    currentProject() {
      return this.$store.state.base.landlordDetails?.projects.find((item) => {
        return item.slug === this.activeProjectSlug
      })
    },
    spaces() {
      return (
        this.$store.state.base.landlordDetails?.spaces?.filter((sp) => {
          return sp.project_id === this.currentProject?.id
        }) || []
      )
    },
    amenities() {
      return this.$store.state.base?.landlordDetails?.amenities || []
    },
    floors() {
      return (
        this.$store.state.base.landlordDetails?.floors?.filter((fl) => {
          return this.buildings.some((bl) => bl.id === fl.building_id)
        }) || []
      )
    },
    buildings() {
      return (
        this.$store.state.base.landlordDetails?.buildings?.filter((bl) => {
          return bl.project_id === this.currentProject.id
        }) || []
      )
    },
    spaceTypes() {
      const existingSpaceTypesSet = new Set(
        this.spaces.map((space) =>
          space.available &&
          typeof space.space_type_id !== 'undefined' &&
          space.space_type_id !== null
            ? space.space_type_id
            : -1
        )
      )

      return (
        this.$store.state.base.landlordDetails?.spaceTypes.filter((spaceType) =>
          Array.from(existingSpaceTypesSet).includes(spaceType.id)
        ) || []
      )
    },
    currency() {
      return this.$store.state?.base?.meta?.general?.baseCurrencySymbol || '€'
    },
    availableSpaces() {
      const buildingObj = {}
      const floorObj = {}
      this.spaces.forEach((sp) => {
        if (!buildingObj[sp.building_id]) {
          buildingObj[sp.building_id] = {
            available: 0,
            total: 0
          }
        }
        if (!floorObj[sp.floor_id]) {
          floorObj[sp.floor_id] = {
            available: 0,
            total: 0
          }
        }

        buildingObj[sp.building_id].total++
        floorObj[sp.floor_id].total++
        if (sp.available) {
          buildingObj[sp.building_id].available++
          floorObj[sp.floor_id].available++
        }
      })
      return {
        building: buildingObj,
        floor: floorObj
      }
    },
    filters() {
      return this.$store.state.project.filters
    },
    filteredSpaces() {
      let tempFilteredSpaces = [...this.spaces]

      if (!this.filters.showSoldApartments && !this.filters.showOnlySoldApartments) {
        tempFilteredSpaces = tempFilteredSpaces.filter((sp) => sp.available)
      }
      if (this.filters.showOnlySoldApartments) {
        tempFilteredSpaces = tempFilteredSpaces.filter((sp) => !sp.available)
      }
      if (this.filters.building !== -1) {
        tempFilteredSpaces = tempFilteredSpaces.filter(
          (s) => Number(s.building_id) === Number(this.filters.building)
        )
      }
      if (this.filters.floors.length && !this.filters.floors.includes('')) {
        tempFilteredSpaces = tempFilteredSpaces.filter((s) =>
          this.filters.floors.includes(Number(s.floor.no))
        )
      }
      // if (this.filters.noRooms.id !== -1) {
      //   tempFilteredSpaces = tempFilteredSpaces.filter(
      //     (s) =>
      //       Number(s.number_of_rooms) === Number(this.filters.noRooms.numberOfRooms) &&
      //       s.space_type === this.filters.noRooms.spaceType
      //   )
      // }
      if (this.filters.spaceType !== -1) {
        tempFilteredSpaces = tempFilteredSpaces.filter(
          (s) => s.space_type_id === this.filters.spaceType
        )
      }
      if (this.filters.orientation !== 'all') {
        tempFilteredSpaces = tempFilteredSpaces.filter(
          (s) => s.orientation === this.filters.orientation
        )
      }
      if (this.filters.surfaceRange.length > 0) {
        tempFilteredSpaces = tempFilteredSpaces.filter((s) => {
          if (
            this.betweenRange(
              s.util_sqm,
              this.filters.surfaceRange[0],
              this.filters.surfaceRange[1]
            )
          ) {
            return s
          }
          return false
        })
      }
      if (this.filters.priceRange.length > 0) {
        tempFilteredSpaces = tempFilteredSpaces.filter((s) => {
          if (this.betweenRange(s.price, this.filters.priceRange[0], this.filters.priceRange[1])) {
            return s
          }
          return false
        })
      }
      if (this.filters.amenities?.length > 0) {
        tempFilteredSpaces = tempFilteredSpaces.filter((s) => {
          const result = s.amenities
            .map((am) => am.id)
            .filter((am) => this.filters.amenities.includes(am.toString()))
          return result.length > 0
        })
      }

      return tempFilteredSpaces
    },
    finalStructureSpaces() {
      const obj = {}
      this.filteredSpaces?.forEach((sp) => {
        if (!obj[sp.building_id]) {
          const building = this.buildings.find((b) => {
            return b.id === sp.building_id
          })
          obj[sp.building_id] = {
            id: sp.building_id,
            title: building?.name || '',
            noSpacesResult: 0,
            child: {},
            buildingDetails: building
          }
        }
        if (!obj[sp.building_id].child[sp.floor_id]) {
          const floor = this.floors.find((f) => {
            return f.id === sp.floor_id
          })
          obj[sp.building_id].child[sp.floor_id] = {
            id: sp.floor_id,
            title: this.$t('floor', { floor: floor.no }) || '',
            no: floor.no,
            subtitle: '', // `${this.availableSpaces.floor[sp.floor_id].available} Units Available`,
            noSpacesResult: 0,
            fullyLeased:
              this.availableSpaces.floor[sp.floor_id].available === 0 ? this.$t('FULLY SOLD') : '',
            child: {},
            floorDetails: floor
          }
        }
        // if (!obj[sp.building_id].child[sp.floor_id].child[sp.number_of_rooms]) {
        //   obj[sp.building_id].child[sp.floor_id].child[sp.number_of_rooms] = []
        // }

        if (!obj[sp.building_id].child[sp.floor_id].child[sp.space_type_id]) {
          obj[sp.building_id].child[sp.floor_id].child[sp.space_type_id] = []
        }

        // TODO: [Undo modifications if you have to calculate total_useful_area by including garden and balcony surfaces]
        // const balconySurface =
        //   sp.units?.find((u) => u.roomType.name.toLowerCase() === 'Balcony'.toLowerCase())
        //     ?.surface || 0
        // const gardenSurface =
        //   sp.units?.find((u) => u.roomType.name.toLowerCase() === 'Garden'.toLowerCase())
        //     ?.surface || 0

        // obj[sp.building_id].child[sp.floor_id].child[sp.number_of_rooms].push({
        obj[sp.building_id].child[sp.floor_id].child[sp.space_type_id].push({
          id: sp.id,
          title: sp.alias ? `Ap. ${sp.alias}` : `Ap. ${sp.space_code}`,
          surface: sp.util_sqm,
          // total_sqm: Number((sp.constructed_sqm + balconySurface + gardenSurface).toFixed(2)),
          // useful_sqm: Number((sp.util_sqm + balconySurface + gardenSurface).toFixed(2)),
          total_sqm: Number(sp.constructed_sqm.toFixed(2)),
          useful_sqm: Number(sp.util_sqm.toFixed(2)),
          cardinalPoint: sp.orientation,
          isSold: sp.available === false ? this.$t('SOLD') : '',
          content: {
            // imgUrl: 'https://cdn-bs-public.s3.eu-central-1.amazonaws.com/images/projects/intero/logo-black.png',
            description: this.mapAmenitiesTranslated(sp),
            price: `${sp.price.toLocaleString('de-DE')} ${this.currency}`,
            buttons: []
          },
          spaceDetails: sp
        })

        obj[sp.building_id].noSpacesResult++
        obj[sp.building_id].child[sp.floor_id].noSpacesResult++
      })

      return Object.values(obj).map((building) => {
        building.child = Object.values(building.child).map((floor) => {
          /*
             Sort spaces by "space_type" categories using the items returned by the computed property "spaceTypes",
             The "spaceTypes" items were previously sorted on the backend side by "sequence_order" property
          */
          const spaceTypesIds = Object.keys(floor.child)
          const spaceTypesSortedIds = this.spaceTypes
            .filter((spaceType) => spaceTypesIds.some((id) => Number(id) === spaceType.id))
            .map((spaceType) => spaceType.id)
          const sortedChildBySequenceOrder = new Map()
          for (const id of spaceTypesSortedIds) {
            sortedChildBySequenceOrder.set(id, floor.child[id])
          }
          return { ...floor, child: sortedChildBySequenceOrder }
        })
        building.child.sort((a, b) => a.no - b.no)
        return building
      })
    },
    requestOfferSpaces() {
      return this.$store.state?.requestOffer?.spaces || []
    },
    activeSpace() {
      return parseInt(this.$route.params.spaceId)
    },
    engine3d() {
      return this.$engine3d || window.engine3d
    },
    portfolioPageDisabled() {
      return this.$store.state.base?.meta?.generalConfig?.portfolioPageDisabled || false
    },
    contractShowcaseEnabled() {
      return (
        this.$store.getters.featuresForProject(this.activeProjectSlug)?.contractShowcase || false
      )
    },
    filterSoldSpacesEnabled() {
      return (
        this.$store.getters.featuresForProject(this.activeProjectSlug)?.filterSoldSpaces || false
      )
    }
  },
  watch: {
    // spaces(val) {
    //   this.filteredSpaces = val
    // },
    activeProject() {
      const activeProject = this.$store.state.project.activeProject
      this.$store.state.project.projects.forEach((project) => {
        if (project.slug === activeProject) {
          this.project = project
          return project
        }
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      const manager = this.engine3d.getClientManager()
      manager.onHighlightClick = (spaceId, buildingCode) => {
        if (!spaceId) return console.warn('Space is not in DB yet')
        const spaceData = this.getSpaceById(spaceId)
        spaceData.building = buildingCode
        this.$store.dispatch('building/viewSpace', {
          space: spaceData.space_code,
          building: spaceData.building,
          floor: null,
          spaceData
        })
        this.handleHighlightTrigger(buildingCode, spaceData.space_code)
      }
    })
  },
  methods: {
    mapAmenitiesTranslated(space) {
      let filteredAmenities =
        this.amenities?.filter((am1) => space.amenities.some((am2) => am1.id === am2.id)) || []
      filteredAmenities = filteredAmenities?.map((am) => am.translation.label[this.locale]) || []
      if (filteredAmenities.length === 0) return this.$t('No amenities found')
      return filteredAmenities.join(', ')
    },
    openRequestOfferModal(space, building) {
      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'request_offer_open',
          payload: {
            zone: 'building'
          }
        })
      }
      this.$store.dispatch(modalConstants.withNamespace(modalConstants.action.CHANGE_MODAL_STATE), {
        showModal: true,
        modalType: 'request-offer',
        data: { space: { ...space, building } }
      })
      document.body.classList.add('disable-scroll')
    },
    betweenRange(x, min, max) {
      return x >= min && x <= max
    },
    getSpaceById(spaceId) {
      return this.spaces.find((s) => s.id === spaceId)
    },
    handleHighlightTrigger(buildingCode, spaceCode) {
      const building = this.buildings.find((b) => b.code === buildingCode)
      const space = this.spaces.find(
        (sp) => sp.building_id === building.id && sp.space_code === spaceCode
      )
      if (!space) {
        return
      }
      const spaceName = buildingCode + '_' + spaceCode
      this.$refs[this.clickedHighlight]?.[0]?.close(false)
      if (this.clickedHighlight !== spaceName) {
        this.$nextTick(() => {
          if (
            !this.$refs[`building_${building.id}`]?.[0] ||
            !this.$refs[`floor_${space.floor_id}`]?.[0] ||
            !this.$refs[`${spaceName}`]?.[0]
          ) {
            return
          }
          this.$refs[`building_${building.id}`][0].open()
          this.$refs[`floor_${space.floor_id}`][0].open()
          this.$refs[`${spaceName}`][0].open(false)

          if (this.$refs.drawer.isOpen) {
            setTimeout(() => {
              this.$refs[`${spaceName}`][0].$el?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest'
              })
            }, 100)
          }
        })
        this.$store.dispatch(
          universeConstants.withNamespace(universeConstants.action.TOGGLE_APARTMENT_VIEW),
          true
        )
        this.$store.dispatch(
          projectConstants.withNamespace(projectConstants.action.CHANGE_CURRENT_SPACE),
          space.id
        )
        this.clickedHighlight = spaceName
        if (window.innerWidth < 768) {
          if (!this.$store.state.tutorial.BUILDING_HIGHLIGHT_EXIT_MOBILE) {
            document.querySelector('.building-highlight-info').style.display = 'block'
          }
        }
      } else {
        this.$store.dispatch(
          universeConstants.withNamespace(universeConstants.action.TOGGLE_APARTMENT_VIEW),
          false
        )
        this.$store.dispatch(
          projectConstants.withNamespace(projectConstants.action.CHANGE_CURRENT_SPACE),
          null
        )
        this.clickedHighlight = ''
        if (window.innerWidth < 768) {
          if (!this.$store.state.tutorial.BUILDING_HIGHLIGHT_EXIT_MOBILE) {
            this.$store.dispatch(
              tutorialConstants.namespaceAction.TRIGGER_PANEL,
              'BUILDING_HIGHLIGHT_EXIT_MOBILE'
            )
          }
        }
      }
    },
    viewOrHideSpace(open, spaceCode, buildingCode, floorCode, space) {
      const { name: routeName } = this.$route
      if (
        routeName.includes('project-project') ||
        routeName.includes('embed-project') ||
        (this.portfolioPageDisabled && routeName.includes('index'))
      ) {
        this.$refs[this.clickedHighlight]?.[0]?.close(false)
        if (open) {
          this.$store.dispatch(
            universeConstants.withNamespace(universeConstants.action.TOGGLE_APARTMENT_VIEW),
            true
          )
          this.$store.dispatch(
            projectConstants.withNamespace(projectConstants.action.CHANGE_CURRENT_SPACE),
            space.id
          )
          this.viewSpace(spaceCode, buildingCode, floorCode, space)
          this.clickedHighlight = `${buildingCode}_${spaceCode}`
          if (window.innerWidth < 768) {
            if (
              document.querySelector('.building-highlight-info') &&
              !this.$store.state.tutorial.BUILDING_HIGHLIGHT_EXIT_MOBILE
            ) {
              document.querySelector('.building-highlight-info').style.display = 'block'
            }
          }
        } else {
          this.$store.dispatch(
            projectConstants.withNamespace(projectConstants.action.CHANGE_CURRENT_SPACE),
            null
          )
          this.$store.dispatch(
            universeConstants.withNamespace(universeConstants.action.TOGGLE_APARTMENT_VIEW),
            false
          )
          this.resetView(buildingCode)
          this.clickedHighlight = ''
          if (window.innerWidth < 768) {
            if (!this.$store.state.tutorial.BUILDING_HIGHLIGHT_EXIT_MOBILE) {
              this.$store.dispatch(
                tutorialConstants.namespaceAction.TRIGGER_PANEL,
                'BUILDING_HIGHLIGHT_EXIT_MOBILE'
              )
            }
          }
        }
      }

      if (this.defaultOpen && this.clickedHighlight) {
        this.$nextTick(() =>
          this.$refs[this.clickedHighlight][0].$el.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'nearest'
          })
        )
      }
    },
    resetView(buildingCode) {
      const manager = this.engine3d.getClientManager()
      manager.removeHighlight(buildingCode)
    },
    viewSpace(spaceCode, buildingCode, floorCode, space) {
      const manager = this.engine3d.getClientManager()
      manager.showHighLight(`${buildingCode}_${space.space_code}`)
    },
    handleTabChange(tab) {
      this.activeTab = tab
    },
    exploreSpace(spaceId) {
      if (this.enableEmbedVersion === true) {
        this.$router.push({
          path: `/embed/${this.activeProjectSlug}/space/${spaceId}`
        })
      } else {
        this.$router.push({
          path: `/project/${this.activeProjectSlug}/space/${spaceId}`
        })
      }
    },
    addToShortlist(space, floor, building) {
      this.$store.dispatch(
        universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX),
        {
          open: true
        }
      )
      setTimeout(
        () =>
          this.$store.dispatch(
            universeConstants.withNamespace(universeConstants.action.TOGGLE_SHORTLIST_INFO_BOX),
            {
              open: false
            }
          ),
        5000
      )
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.ADD_SPACE),
        {
          ...space,
          building,
          floor
        }
      )
    },
    removeFromShortlist(space, floor, building) {
      this.$store.dispatch(
        requestOfferConstants.withNamespace(requestOfferConstants.action.REMOVE_SPACE),
        {
          ...space,
          building,
          floor
        }
      )
    },
    toggleDrawer() {
      this.$refs.drawer?.toggleFilter()
    },
    closeDrawer() {
      this.$refs.drawer?.close()
    },
    openDrawer() {
      this.$refs.drawer?.open()
    }
  }
}
