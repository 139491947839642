import { render, staticRenderFns } from "./OrganismContentSlider.vue?vue&type=template&id=48fce686&"
import script from "./OrganismContentSlider.vue?vue&type=script&lang=js&"
export * from "./OrganismContentSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonSliderAtomContentSlider: require('/app/components/atoms/common/slider/AtomContentSlider.vue').default,MoleculesCommonMoleculeContentSlider: require('/app/components/molecules/common/MoleculeContentSlider.vue').default})
