
import { WebRequest } from '~/helpers/api'

export default {
  name: 'OrganismParkingAccessV2',
  props: {
    parkingAccess: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    let activeTab = ''
    if (this.parkingAccess && this.parkingAccess.length) {
      activeTab = this.parkingAccess[0].slug
    }
    return {
      parkingItems: [],
      animationsLoaded: false,
      skipAnimation: true,
      animation: {},
      activeTab
    }
  },
  computed: {
    cdnBase() {
      return this.$store.getters.cdnBase
    }
  },
  watch: {
    parkingAccess() {
      this.activeTab = this.parkingAccess[0].slug
      this.populateAnimationConfigs()
    }
  },
  beforeUpdate() {
    if (this.parkingAccess && this.parkingAccess.length && !this.activeTab) {
      this.activeTab = this.parkingAccess[0].slug
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.populateAnimationConfigs()
      const options = {
        root: null, // Use the viewport as the root element
        rootMargin: '0px', // No margin around the root element
        threshold: 0.9 // Trigger when at least 50% of the element is visible
      }
      const observer = new IntersectionObserver(this.handleIntersection, options)

      // Start observing the section
      if (this.$refs.sectionToObserve) {
        observer.observe(this.$refs.sectionToObserve)
      }
    })
  },
  methods: {
    async populateAnimationConfigs() {
      const parkingItems = []
      for (const parking of this.parkingAccess) {
        const newParking = { ...parking }
        if (typeof newParking.animation === 'string') {
          const result = await this.getAnimationConfig(newParking.animation)
          newParking.animation = result.data
          parkingItems.push(newParking)
        } else {
          parkingItems.push(newParking)
        }
      }
      this.parkingItems = parkingItems
      this.$nextTick(() => {
        this.animationsLoaded = true
      })
    },
    async getAnimationConfig(configFileUrl = '') {
      return await WebRequest.GET(`${this.cdnBase}/${configFileUrl}`)
    },
    assetUrl(image) {
      if (!image) return ''
      if (image.includes('://')) {
        return image
      }
      return `${this.cdnBase}/${image}`
    },
    handleAnimation(animation, id) {
      this.animation[id] = animation
    },
    playAnimation(id) {
      this.skipAnimation = false
      this.activeTab = id
      this.resetAllAnimations()
      if (!this.animation[id]) return
      this.animation[id].setSpeed(1)
      this.animation[id].play()
      const { totalFrames, endFrame } = this.animation[id]
      this.animation[id].playSegments([0, endFrame || totalFrames], true)
    },
    resetAnimation(id) {
      if (!this.animation[id]) return
      this.animation[id].goToAndStop(0)
    },
    resetAllAnimations() {
      Object.keys(this.animation).forEach((key) => {
        this.resetAnimation(key)
      })
    },
    handleIntersection(entries, observer) {
      // The callback function to handle the intersection
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Section entered the viewport
          this.playAnimation(this.activeTab)
        } else {
          // Section left the viewport
        }
      })
    }
  }
}
