
export default {
  name: 'OrganismProjectPortfolio',
  props: {
    portfolioData: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      activeProject: 'item-0'
    }
  },
  methods: {
    handleThumbChange(tabData) {
      this.activeProject = tabData.tabId
      this.$refs.map.triggerMarkerById(tabData.projectId)
    }
  }
}
