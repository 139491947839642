
export default {
  name: 'OrganismCertifications',
  props: {
    listCircleData: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    }
  },
  methods: {
    resourceUrl(url) {
      if (url.includes('://')) {
        return url
      }
      return `${this.$store.getters['base/cdnBase']}/${url}`
    }
  }
}
