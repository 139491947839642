import { render, staticRenderFns } from "./OrganismProjectSlider.vue?vue&type=template&id=4f0344fc&"
import script from "./OrganismProjectSlider.vue?vue&type=script&lang=js&"
export * from "./OrganismProjectSlider.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsPortfolioAtomProjectSliderThumb: require('/app/components/atoms/portfolio/AtomProjectSliderThumb.vue').default,MoleculesPortfolioMoleculeProjectSliderThumb: require('/app/components/molecules/portfolio/MoleculeProjectSliderThumb.vue').default,AtomsPortfolioAtomProjectSliderBackground: require('/app/components/atoms/portfolio/AtomProjectSliderBackground.vue').default,MoleculesPortfolioMoleculeProjectSliderBackground: require('/app/components/molecules/portfolio/MoleculeProjectSliderBackground.vue').default})
