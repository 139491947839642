
export default {
  name: 'OrganismDrawer',
  props: {
    defaultOpen: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.defaultOpen,
      windowHeight: typeof window !== 'undefined' ? window.innerHeight : 0
    }
  },
  methods: {
    toggleFilter() {
      this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    },
    open() {
      this.isOpen = true
    }
  }
}
